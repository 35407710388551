const pressLinks = [
	{
		id: 'boost-performance-with-the-tag',
		date: 'October 2nd 2024',
		url: 'https://www.webgains.com/public/en/boost-performance-with-the-tag/',
		desc: 'pressLinks21',
	},
	{
		id: 'myth-busting-the-affiliate-marketing-channel',
		date: 'July 3rd 2024',
		url: 'https://www.thedrum.com/open-mic/myth-busting-the-affiliate-marketing-channel',
		desc: 'pressLinks20',
	},
	{
		id: 'key-news-and-events',
		date: 'May 17th 2024',
		url: 'https://www.awin.com/gb/news-and-events/awin-news/pma-2024-wins',
		desc: 'pressLinks19',
	},
	{
		id: 'key-power-100',
		date: 'May 14th 2024',
		url: 'https://www.awin.com/gb/power-100',
		desc: 'pressLinks18',
	},
	{
		id: 'key-new-partners',
		date: 'Apr 17th 2024',
		url: 'https://theapma.co.uk/key-new-partners-who-are-driving-change/',
		desc: 'pressLinks17',
	},
	{
		id: 'best-technology-and',
		date: 'May 16th 2024',
		url: 'https://performancemarketingawards.co.uk/categories/best-technology-and-telecoms-campaign/',
		desc: 'pressLinks16',
	},
	{
		id: 'brand-part-secret-sales',
		date: 'Apr 11th 2024',
		url: 'https://www.awin.com/gb/advertisers/case-studies/secretsales-tyviso',
		desc: 'pressLinks14',
	},
	{
		id: 'shortlisted-for-eight-awards',
		date: 'Apr 4th 2024',
		url: 'https://www.awin.com/gb/news-and-events/awin-news/pma-shortlist',
		desc: 'pressLinks15',
	},
	{
		id: 'marketers-drawn-to-the',
		date: 'Mar 27th 2024',
		url: 'https://www.thedrum.com/open-mic/marketers-drawn-to-the-affiliate-channel-in-order-to-access-emerging-tech-survey-finds',
		desc: 'pressLinks13',
	},
	{
		id: 'brand-partnerships-wrapped',
		date: 'Feb 23rd 2024',
		url: 'https://www.awin.com/gb/affiliate-marketing/brand-partnerships-wrapped',
		desc: 'pressLinks12',
	},
	{
		id: 'exploring-the-value-of-gwp',
		date: 'Nov 21st 2023',
		url: 'https://www.imrg.org/blog/exploring-the-value-of-gift-with-purchase/',
		desc: 'pressLinks11',
	},
	{
		id: 'plusnet-tyviso-brand-partnerships',
		date: 'Nov 9th 2023',
		url: 'https://www.awin.com/gb/advertisers/case-studies/plusnet-tyviso-brand-partnerships',
		desc: 'pressLinks10',
	},
	{
		id: 'recipe-for-success',
		date: 'Aug 3rd 2023',
		url: 'https://www.awin.com/gb/advertisers/case-studies/a-recipe-for-success-wild-simplycook-and-tyviso',
		desc: 'pressLinksRecipeForSuccess',
	},
	{
		id: 'gift-with-purchase-partnership',
		date: 'Apr 19th 2023',
		url: 'https://www.awin.com/gb/news-and-events/awin-news/gift-with-purchase-partnership',
		desc: 'pressLinkAwinGwp',
	},
	{
		id: 'publisher-webgains',
		date: 'Jan 19th 2023',
		url: 'https://www.webgains.com/public/en/publisher-spotlight-tyviso/',
		desc: 'pressLinkWebgains',
		self: '_self',
	},
	{
		id: 'brand-partnerships-wrapped',
		date: 'Dec 13th 2022',
		url: 'https://www.awin.com/gb/affiliate-marketing/brand-partnerships-wrapped',
		desc: 'pressLinkPartnerships',
	},
	{
		id: 'introduction-brand-partnerships',
		date: 'Nov 23rd 2022',
		url: 'https://www.awin.com/ca/podcasts/introduction-brand-partnerships',
		desc: 'pressLinkAwinTalks',
	},
	{
		id: 'publisher-awin',
		date: 'Aug 15th 2022',
		url: 'https://www.awin.com/gb/news-and-events/awin-news/tyviso-perx-rewards-integration',
		desc: 'pressLinkAwin',
	},
	{
		id: 'publisher-connect',
		date: 'July 12th 2022',
		url: 'https://www.tradedoubler.com/en/blog/publisher-spotlight-tyviso/',
		desc: 'pressLinkConnect',
	},
	{
		id: 'publisher-spotlight',
		date: 'Apr 14th 2022',
		url: 'https://www.tradedoubler.com/en/blog/brand-to-brand-recommendations/',
		desc: 'pressLinksSpotlight',
	},
	{
		id: 'play-it',
		date: 'Mar 29th 2022',
		url: 'https://www.youtube.com/watch?v=sYPN8TuU9OY',
		desc: 'pressLinksWebgains',
	},
];

export default pressLinks;